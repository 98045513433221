//Styles
import './css/App.css';
import './css/index.css';
import './css/font-awesome.css';
import './css/style.css'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore/lite';

import {useState} from 'react'


const App = () => {

  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [subscribed, setSubscribed] = useState(false)
  const [error, setError] = useState('')

  const firebaseConfig = {
    apiKey: "AIzaSyDBiT6doZztXIxz2BjZ9haOyFRndRenbus",
    authDomain: "tagon-6e79a.firebaseapp.com",
    databaseURL: "https://tagon-6e79a-default-rtdb.firebaseio.com",
    projectId: "tagon-6e79a",
    storageBucket: "tagon-6e79a.appspot.com",
    messagingSenderId: "844475242585",
    appId: "1:844475242585:web:31c99a63139800e9f87948",
    measurementId: "G-CN52M45PV3"
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);

  const save = async () => {
    
    try {
      if(name.replace(/\s/g, '') != '' && mail.replace(/\s/g, '') != '' && mail.includes('@') ){
        const docRef = await addDoc(collection(db, "registros"), {
          name: name,
          mail: mail,
          subDate: new Date()
        });
        setError('')
        setSubscribed(true)
      }
      else {
        setError('Nombre o correo invalido')
      }
     
    } catch (e) {

      console.error("Error adding document: ", e);
    }
    
  }

  const changeName = (name) => {
      setName(name.target.value)
  }

  const changeMail = (mail) => {
      setMail(mail.target.value)
  }



  return (
    
    <div className="App">
      <div className="main-route_con">
        <div className="container-logo">
          <img src="./images/logotipo.svg"></img>
        </div>
        <div className="route-grids">
          <div className="route-right">
            <div className="route-dot">
              <div className="route-heading">
                <h3>Tagon</h3>
                <h4>Vive el futuro del Networking</h4>
              </div>
            </div>
                <div className="newsletter">
                  <h2>Suscribete para el lanzamiento</h2>
                  {
                    !subscribed ? 
                    <>
                      <input className='input-sub' type="text" name="email" size="30" required="" placeholder="Nombre" onChange={changeName} value={name}/>
                      <input className='input-sub' type="text" name="email" size="30" required="" placeholder="Correo" onChange={changeMail} value={mail}/>
                      <input className='button-sub' type="submit" value="Subscribe" onClick={save}/>
                      {
                        error == '' ? <></>
                        : <h4>{error}</h4>
                      }
                    </> :
                    <h4>Recibiras una invitacion a tu correo para el lanzamiento</h4>

                  }
                
                </div>
          </div>
          <div className="route-left">
            <div className="route1-dot">
              <div className="route2-title">
                <h2>Próximamente</h2>
              </div>
              {/* <div className="route1-timer"> 
                <div className="clock">
                  <div className="column days">
                    <div className="timer" id="days"></div>
                    <div className="text">Días</div>
                  </div>
                  <div className="timer days"></div>
                  <div className="column">
                    <div className="timer" id="hours"></div>
                    <div className="text">Horas</div>
                  </div>
                  <div className="timer"></div>
                  <div className="column">
                    <div className="timer" id="minutes"></div>
                    <div className="text">Min.</div>
                  </div>
                  <div className="timer"></div>
                  <div className="column">
                    <div className="timer" id="seconds"></div>
                    <div className="text">Seg.</div>
                  </div>
                  <div className="clear"> </div>
                </div>	 
              </div> */}
              <div className="route_con_-social">
                <div className="route-social-grids">
                  <ul>
                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fa fa-rss"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="clear"> </div>
        </div>
        <div className="copyright">
          <p> © 20021 Tagon . Todos los derechos reservados.</p>
        </div>
      </div>
    </div>
  );
}

export default App;
